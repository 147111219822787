import { render, staticRenderFns } from "./OnSiteMessagePopupForm.vue?vue&type=template&id=386911fe&scoped=true&"
import script from "./OnSiteMessagePopupForm.vue?vue&type=script&lang=js&"
export * from "./OnSiteMessagePopupForm.vue?vue&type=script&lang=js&"
import style0 from "./OnSiteMessagePopupForm.vue?vue&type=style&index=0&id=386911fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386911fe",
  null
  
)

export default component.exports